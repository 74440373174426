import React from 'react'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <div className="container">
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>This page does not exist... the sadness.</p>
  </div>
)

export default NotFoundPage
